import React, { useEffect, useState } from "react"
import Cookies from "js-cookie"

// Assets
import logo from "../../images/logoForm.png"
import tick from "../../../assets/images/icons/tickform.svg"
import styles from "../../styles/page_casaCoronaForm.module.scss"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
// Components
import AgeGate2 from "../../components/agegate"
import FooterCasaCorona from "../../components/FooterCasaCorona"

import terminos from "../../../static/TyC_Casa_Corona_febrero_2022.pdf"
import privacidad from "../../../static/PP_AO.pdf"

import axios from "axios"
import AgeGateBoolean from "../AgeGate/AgegateBoolean"

const FormPage = ({ fetchURL, type, location }) => {
  const [age, setAge] = useState(true)
  // const sessions = data.allSessions.edges
  // Form Items
  const [dni, setDni] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [lastName, setLastName] = useState("")
  const [activity, setActivity] = useState("")
  const [province, setProvince] = useState("")
  const [preference_question, setPreference_question] = useState("")
  const [terms, setTerms] = useState(false)
  const [eventName, setEventName] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState("")

  const [events, setEvents] = useState(false)
  const [loading, setLoading] = useState(false)
  const [approved, setApproved] = useState(false)
  const [isMobile1, setIsMobile1] = useState(false)
  const [error, setError] = useState(false)

  const params = new URLSearchParams(location?.search)

  //const db = getDatabase(app)

  // const activitiesRef = ref(db,"activities")
  /*
        onValue(activitiesRef, (snapshot) => {
          const data = snapshot.val();
          console.log("activities",data)
        });
        */

  const formatDate = (value) => {
    const cleanValue = value.replace(/\D/g, "") // Remove non-digit characters
    let formattedDate = ""

    if (cleanValue.length <= 2) {
      formattedDate = cleanValue
    } else if (cleanValue.length <= 4) {
      formattedDate = `${cleanValue.slice(0, 2)}/${cleanValue.slice(2)}`
    } else {
      formattedDate = `${cleanValue.slice(0, 2)}/${cleanValue.slice(
        2,
        4
      )}/${cleanValue.slice(4, 8)}`
    }

    return formattedDate
  }

  const isDateValid = (dateString) => {
    const date = new Date()
    const year = date.getFullYear()
    if (dateString.length < 10) return false
    if (Number(dateString.split("/")[0]) > 31) return false
    if (Number(dateString.split("/")[1]) > 12) return false
    if (Number(dateString.split("/")[2]) >= year) return false
    return true
  }

  const switchTYP = () => {
    switch (type) {
      case "session":
        return (
          <h4>
            ¡Gracias por registrarte! ({name}, DNI: {dni}) <br />
            Revisá tu correo electrónico, hacé click en el botón "Quiero mi
            Corona" y seguí unos breves pasos para obtener tu Corona sin cargo y
            seguir disfrutando de la montaña
          </h4>
        )
      case "winter":
        return <h4>Ya estas participando por nuestros premios</h4>
      default:
        return (
          <>
            <h4>
              Ya estás participando por entradas
              <br />
              para el Sunset del 27 de febrero.
            </h4>
            <h4>
              Pronto recibirás un mail
              <br />
              avisándote si sos uno de los ganadores.
            </h4>
          </>
        )
    }
  }

  useEffect(() => {
    window.dataLayer.push({
      brand: "Corona",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Form",
      pageType: "Registration",
      SiteType: "Registration",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.cervezacorona.com.ar/form",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    })
  }, [])

  useEffect(() => {
    let local = sessionStorage.getItem("age")
    if (local) setAge(false)
    window.innerWidth <= 800 ? setIsMobile1(true) : setIsMobile1(false)
  }, [])

  const isFormComplete = () => {
    if (
      name &&
      lastName &&
      email &&
      dni &&
      province &&
      terms &&
      preference_question
    ) {
      return true
    }
    return false
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (
      name !== "" &&
      lastName !== "" &&
      email !== "" &&
      dni !== "" &&
      province !== "" &&
      preference_question !== "" &&
      dateOfBirth !== ""
    ) {
      if (!isDateValid(dateOfBirth)) {
        setError({ message: "Fecha de nacimiento invalida" })
        setTimeout(() => {
          setError(false)
        }, 2000)
        return
      }
      setLoading(true)

      const emailRes = await axios.post(fetchURL + "/check-email", { email })
      if (emailRes.data?.isRegistered) {
        setLoading(false)
        setError({ message: `el email: ${email} ya esta registrado` })
        setTimeout(() => {
          setError(false)
        }, 2000)
        return
      }

      if (type === "session") {
        const dniRes = await axios.post(fetchURL + "/check-dni", { dni })
        if (dniRes.data?.isRegistered) {
          setLoading(false)
          setError({ message: `el dni: ${dni} ya esta registrado` })
          setTimeout(() => {
            setError(false)
          }, 2000)
          return
        }
      }

      let td = Cookies.get("_td")
      let obj = {
        name,
        last_name: lastName,
        email,
        dni,
        state: province,
        preference_question,
        activity: params.get("t"),
        date_of_birth: dateOfBirth,
        event_id: params.get("e_id"),
        td,
        mail: true
      }

      fetch(fetchURL, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      })

      setApproved(true)
      setLoading(false)
    }
  }

  const handleTerms = () => {
    return setTerms(!terms)
  }

  return !age ? (
    approved ? (
      <div className={styles.green}>
        <img src={tick} />
        {/* <p>
          Tu reserva se
          <br />
          registró con éxito.
        </p> */}
        {switchTYP()}

        {/* <h5>Reservaste:</h5>
        <div className={styles.reservas}>
          <div className={styles.left}>
            <h2>
              {activity} - {eventName}
            </h2>
          </div>
        </div> */}
      </div>
    ) : isMobile1 ? (
      <>
        <div>
          <div className={styles.imageContainer}>
            <div className={styles.formImages}>
              {/*<img className={styles.tick} src={logo} />*/}
            </div>
            <h2>
              Registrate para poder <br /> asistir a los eventos.
            </h2>
          </div>
          <form onSubmit={handleSubmit} className={styles.formContainer}>
            <h3>Nombre*</h3>
            <div className={styles.bordeInsc}>
              <div className="col px-0">
                <input
                  requiredx
                  type="text"
                  className={styles.casaCoronaInscription}
                  placeholder="Nombre "
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
            </div>
            <h3>Apellido*</h3>
            <div className={styles.bordeInsc}>
              <div className="col px-0">
                <input
                  required
                  type="text"
                  className={styles.casaCoronaInscription}
                  placeholder="Apellido"
                  onChange={(e) => setLastName(e.target.value)}
                ></input>
              </div>
            </div>

            <h3>Email*</h3>
            <div className={styles.bordeInsc}>
              <div className="col px-0">
                <input
                  type="email"
                  required
                  className={styles.casaCoronaInscription}
                  placeholder="E-mail"
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>
            </div>

            <h3>DNI*</h3>
            <div className={styles.bordeInsc}>
              <div className="col px-0">
                <input
                  type="number"
                  minlength="6"
                  maxlength="8"
                  required
                  className={styles.casaCoronaInscription}
                  placeholder="DNI"
                  onChange={(e) => setDni(e.target.value)}
                ></input>
              </div>
            </div>

            <h3>Fecha de nacimiento*</h3>
            <div className={styles.bordeInsc}>
              <div className="col px-0">
                <input
                  required
                  className={styles.casaCoronaInscription}
                  placeholder="DD/MM/AAAA"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(formatDate(e.target.value))}
                ></input>
              </div>
            </div>

            <h3>Provincia*</h3>
            <select
              required
              onChange={(e) => setProvince(e.target.value)}
              id="first-choice"
              className="selectProvinciasM"
            >
              <option disabled selected value="">
                Provincia
              </option>
              <option value="BsAs CABA">BsAs CABA</option>
              <option value="Buenos Aires">BsAs GBA Norte</option>
              <option value="Buenos Aires">BsAs GBA Sur</option>
              <option value="Buenos Aires">BsAs GBA Oest</option>
              <option value="Buenos Aires">BsAs Fuera de AMBA</option>
              <option value="Catamarca">Catamarca</option>
              <option value="Chaco">Chaco</option>
              <option value="Chubut">Chubut</option>
              <option value="Córdoba">Córdoba</option>
              <option value="Corrientes">Corrientes</option>
              <option value="Entre Ríos">Entre Ríos</option>
              <option value="Formosa">Formosa</option>
              <option value="Jujuy">Jujuy</option>
              <option value="La Rioja">La Rioja</option>
              <option value="Mendoza">Mendoza</option>
              <option value="Misiones">Misiones</option>
              <option value="Neuquén">Neuquén</option>
              <option value="Río Negro">Jujuy</option>
              <option value="Salta">Salta</option>
              <option value="San Juan">San Juan</option>
              <option value="San Luis">San Luis</option>
              <option value="Santa Cruz">Santa Cruz</option>
              <option value="Santa Fe">Santa Fe</option>
              <option value="Santiago del Estero">Santiago del Estero</option>
              <option value="Tierra del Fuego">Tierra del Fuego</option>
              <option value="Tucumán">Tucumán</option>
            </select>
            <h3>ocasión de consumo*</h3>
            <select
              required
              onChange={(e) => setPreference_question(e.target.value)}
              id="first-choice"
              className="selectProvinciasM"
            >
              <option disabled selected value="">
                ocasión
              </option>
              <option value="Juntadas con amigxs en una casa">
                Juntadas con amigxs en una casa
              </option>
              <option value="En las previas">En las previas</option>
              <option value="En fiestas/salidas">En fiestas/salidas</option>
              <option value="Tranqui en casa">Tranqui en casa</option>
              <option value="No tomo cerveza">No tomo cerveza</option>
              <option value="Otro">Otro</option>
            </select>
            <RadioGroup
              aria-label="gender"
              className={styles.inputradio}
              name="termsAndConditions"
              onChange={handleTerms}
            >
              <FormControlLabel
                value="female"
                color="default"
                className={styles.radio}
                control={
                  <Radio
                    value="accept"
                    color="grey"
                    name="radio"
                    inputProps={{ "aria-label": "D", color: "grey" }}
                  />
                }
              />
              <span className={styles.textbases}>
                He leído y acepto los{" "}
                <a
                  className={styles.acolor}
                  href={terminos}
                  download
                  target="_blank"
                >
                  términos de uso
                </a>
                ,{" "}
                <a
                  className={styles.acolor}
                  href={privacidad}
                  download
                  target="_blank"
                >
                  políticas de privacidad
                </a>{" "}
                y activaciones de marketing.
              </span>
            </RadioGroup>

            <div className={styles.btnSubmit}>
              <div className="col px-0">
                {error && (
                  <div class={styles.errorMessage}>{error.message}</div>
                )}
                <button
                  className={(!isFormComplete() || loading) ? styles.btnDisabled : styles.btnEnabled}
                  disabled={!isFormComplete() || loading}
                >
                  RESERVAR
                </button>
              </div>
            </div>
          </form>
          <FooterCasaCorona isMobile={true} />
        </div>
      </>
    ) : (
      <>
        <div style={{ display: "flex", marginBottom: "-2px" }}>
          <div className={styles.formImageD}>
            {/*<img className={styles.tick} src={logo} />*/}
            <h2>
              Registrate para poder <br /> asistir a los eventos.
            </h2>
          </div>

          <div style={{ width: "50%" }}>
            <form onSubmit={handleSubmit} className={styles.formContainerD}>
              <h3>Nombre*</h3>
              <div className={styles.bordeInscD}>
                <div className="col px-0">
                  <input
                    required
                    type="text"
                    className={styles.casaCoronaInscriptionD}
                    placeholder="Nombre "
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
              </div>
              <h3>Apellido*</h3>
              <div className={styles.bordeInscD}>
                <div className="col px-0">
                  <input
                    required
                    type="text"
                    className={styles.casaCoronaInscriptionD}
                    placeholder="Apellido"
                    onChange={(e) => setLastName(e.target.value)}
                  ></input>
                </div>
              </div>

              <h3>Email*</h3>
              <div className={styles.bordeInscD}>
                <div className="col px-0">
                  <input
                    type="email"
                    required
                    className={styles.casaCoronaInscriptionD}
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                </div>
              </div>

              <h3>DNI*</h3>
              <div className={styles.bordeInscD}>
                <div className="col px-0">
                  <input
                    type="number"
                    minlength="6"
                    maxlength="8"
                    required
                    className={styles.casaCoronaInscriptionD}
                    placeholder="DNI"
                    onChange={(e) => setDni(e.target.value)}
                  ></input>
                </div>
              </div>

              <h3>Fecha de nacimiento*</h3>
              <div className={styles.bordeInscD}>
                <div className="col px-0">
                  <input
                    required
                    className={styles.casaCoronaInscriptionD}
                    placeholder="DD/MM/AAAA"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(formatDate(e.target.value))}
                  ></input>
                </div>
              </div>

              <h3>Provincia*</h3>
              <select
                required
                onChange={(e) => setProvince(e.target.value)}
                id="first-choice"
                className="selectProvinciasM"
              >
                <option disabled selected value="">
                  Provincia
                </option>
                <option value="BsAs CABA">BsAs CABA</option>
                <option value="Buenos Aires">BsAs GBA Norte</option>
                <option value="Buenos Aires">BsAs GBA Sur</option>
                <option value="Buenos Aires">BsAs GBA Oest</option>
                <option value="Buenos Aires">BsAs Fuera de AMBA</option>
                <option value="Catamarca">Catamarca</option>
                <option value="Chaco">Chaco</option>
                <option value="Chubut">Chubut</option>
                <option value="Córdoba">Córdoba</option>
                <option value="Corrientes">Corrientes</option>
                <option value="Entre Ríos">Entre Ríos</option>
                <option value="Formosa">Formosa</option>
                <option value="Jujuy">Jujuy</option>
                <option value="La Rioja">La Rioja</option>
                <option value="Mendoza">Mendoza</option>
                <option value="Misiones">Misiones</option>
                <option value="Neuquén">Neuquén</option>
                <option value="Río Negro">Jujuy</option>
                <option value="Salta">Salta</option>
                <option value="San Juan">San Juan</option>
                <option value="San Luis">San Luis</option>
                <option value="Santa Cruz">Santa Cruz</option>
                <option value="Santa Fe">Santa Fe</option>
                <option value="Santiago del Estero">Santiago del Estero</option>
                <option value="Tierra del Fuego">Tierra del Fuego</option>
                <option value="Tucumán">Tucumán</option>
              </select>

              <h3>ocasión de consumo*</h3>
              <select
                required
                onChange={(e) => setPreference_question(e.target.value)}
                id="first-choice"
                className="selectProvinciasM"
              >
                <option disabled selected value="">
                  ocasión
                </option>
                <option value="Juntadas con amigxs en una casa">
                  Juntadas con amigxs en una casa
                </option>
                <option value="En las previas">En las previas</option>
                <option value="En fiestas/salidas">En fiestas/salidas</option>
                <option value="Tranqui en casa">Tranqui en casa</option>
                <option value="No tomo cerveza">No tomo cerveza</option>
                <option value="Otro">Otro</option>
              </select>
              <RadioGroup
                aria-label="gender"
                className={styles.inputradio}
                name="termsAndConditions"
                onChange={handleTerms}
              >
                <FormControlLabel
                  value="female"
                  color="default"
                  className={styles.radio}
                  control={
                    <Radio
                      value="accept"
                      color="grey"
                      name="radio"
                      inputProps={{ "aria-label": "D", color: "grey" }}
                    />
                  }
                />
                <span className={styles.textbases}>
                  He leído y acepto los{" "}
                  <a
                    className={styles.acolor}
                    href={terminos}
                    download
                    target="_blank"
                  >
                    términos de uso
                  </a>
                  ,{" "}
                  <a
                    className={styles.acolor}
                    href={privacidad}
                    download
                    target="_blank"
                  >
                    políticas de privacidad
                  </a>{" "}
                  y activaciones de marketing.
                </span>
              </RadioGroup>

              <div className={styles.btnSubmitD}>
                <div className="col px-0">
                  {error && (
                    <div class={styles.errorMessage}>{error.message}</div>
                  )}
                  <button
                    className={(!isFormComplete() || loading) ? styles.btnDisabled : styles.btnEnabled}
                    disabled={!isFormComplete() || loading}
                  >
                    RESERVAR
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <FooterCasaCorona isMobile={false} />
      </>
    )
  ) : (
    <AgeGateBoolean location={location} setAge={setAge} />
  )
}

/*
export const backgrounds = graphql`
  query casaCoronaForm {
    allSessions(sort: { order: ASC, fields: index }) {
      edges {
        node {
          id
          img
          img_mobile
          index
          secretCode
          title
          subtitle
          sessionDate
        }
      }
    }
  }
`
*/
export default FormPage
